import React, { Suspense, lazy } from "react"
import { createRoot } from "react-dom/client"
import "i18n"
import "@scss/styles.scss"
import "react-bootstrap-typeahead/css/Typeahead.css"
import "react-bootstrap-typeahead/css/Typeahead.bs5.css"

import "jspanel4/es6module/extensions/modal/jspanel.modal"
import "jspanel4/dist/jspanel.min.css"

import "react-calendar/dist/Calendar.css"

import { BrowserRouter as Router, Routes } from "react-router-dom"

import { BACKEND_URL, IS_DEV } from "@/const"
import ConfirmModal from "@components/Common/ConfirmModal"
import Loading from "@views/Loading"
import { PersistGate } from "redux-persist/integration/react"
import { Provider } from "react-redux"
import { Toaster } from "react-hot-toast"
import axios from "axios"
import { persistStore } from "redux-persist"
import store from "./store"
import { ErrorBoundary } from "react-error-boundary"
import ErrorRoute from "./routes/Error"
import UnauthenticatedRoute from "./routes/Unauthenticated"
import HelpRoute from "./routes/Help"
import CalendarRoute from "./routes/Calendar"
import VideoPlayerRoute from "./routes/VideoPlayer"
import MainRoute from "./routes/Main"
import AdminRoute from "./routes/Admin"
import { lazyRetry } from "./routes/utils"
import WellbeingRoute from "./routes/Wellbeing"
import FFAClubSupportRoute from "./routes/FFAClubSupport"

if (module.hot && process.env.NODE_ENV !== "production") {
	module.hot.accept()
}

const persistor = persistStore(store)
window.store = store

axios.defaults.baseURL = BACKEND_URL

const SettingHandler = lazy(() => lazyRetry(() => import("./components/Navigation/SettingHandler.js"), "settingHandler"))

// const _localeCompareFn = String.prototype.localeCompare
// String.prototype.localeCompare = function (compareString, locales, options) {
// 	return _localeCompareFn(compareString, locales || "fi", options)
// }


const tree = <Provider store={store}>
	<PersistGate loading={null} persistor={persistor}>
		<Router>
			<Suspense fallback={<Loading />}>
				<SettingHandler />
				<Routes>
					{/* Error logout handler */}
					{ErrorRoute}

					{/* Unauthenticated routes*/}
					{UnauthenticatedRoute}

					{/* Custom layouts (side panels etc.) */}
					{HelpRoute}
					{CalendarRoute}
					{VideoPlayerRoute}

					{/* Main layout routes */}
					{MainRoute}

					{/* Wellbeing-restricted routes */}
					{WellbeingRoute}

					{/* Admin-only routes */}
					{AdminRoute}

					{/* Finnish Football Association's Club Support routes */}
					{FFAClubSupportRoute}
				</Routes>
				<ConfirmModal />
			</Suspense>
		</Router>
	</PersistGate>
	<Toaster />
</Provider>

const rootElement = document.getElementById("root")
const root = createRoot(rootElement)

root.render(
	IS_DEV ? tree
		: <ErrorBoundary onError={(error, componentStack) => {
			const payload = {
				error: error ? error.toString() : error,
				stack: error ? error.stack : null,
				componentStack,
				location: window.location.href,
			}
			const state = store.getState()
			if (state && state.user) {
				payload.contextData = state.user.contextData
				payload.profileId = state.user.profile
			}
			axios.post("/log-error", payload)
				.then(() => {
					store.dispatch({ type: "FATAL_ERROR" })
				}).catch(err => {
					store.dispatch({ type: "FATAL_ERROR" })
				})
			return null
		}}>
			{tree}
		</ErrorBoundary>
)
