import _ from "lodash"
import i18n from "@/i18n"
import fi from "date-fns/locale/fi"
import sv from "date-fns/locale/sv"
import enGB from "date-fns/locale/en-GB"

/**
 * Returns applicable locale for current language
 * 
 * @param {String} languageCode						Optional: language code for locale
 * @returns {Object}											date-fns locale object
 */
export const getDateLocale = (languageCode) => {
	let dic = {
		fi,
		en: enGB,
		sv
	}
	return dic[languageCode || i18n.language] ?? fi
}

/**
 * Updates localized object paths to use translations for the current language. 
 * Default path values remain if no translation is found.
 * 
 * Dependent on `translations` top-level path in MongoDB object.
 * 
 * @param {Object} obj 																		Object to be translated
 * @param {String} languageCode 													Optional: Language code for translations
 * @param {Object} opts																		Options
 * @param {Boolean} opts.ignoreDisplayNames								When handling a pageCustomization section, sets `displayName`
 *                                                        attributes to empty string to avoid default language page customizations
 * 																												becoming the fallback text instead of localized test name.
 * @returns {Object}																			Translated object
 */
export const localizeObject = (obj, languageCode, opts={}) => {

	const { ignoreDisplayNames } = opts

	if (!obj) {
		return obj
	}
	if (!languageCode) {
		languageCode = i18n.language
	}

	if (obj.translations && languageCode !== "fi") {
		let dest = {}
		obj = _.mergeWith(dest, obj, _.get(obj, ["translations", languageCode]), (objValue, srcValue) => {
			if (srcValue === "" || srcValue == null) {
				if (srcValue === null && ignoreDisplayNames && objValue?.tests) {
					objValue.tests = objValue.tests.map(o => ({ ...o, displayName: "" }))
				}
				return objValue
			}
		})
	}

	return obj
}

// Localize object alias
export const loc = (obj, languageCode, opts) => localizeObject(obj, languageCode, opts)

// Localize array; returns [] if arg is null
export const locList = (list, languageCode, opts) => (list ?? []).map(o => loc(o, languageCode, opts))
// Localize object or array; returns null if arg is null
export const locData = (data, languageCode, opts) => _.isArray(data) ? locList(data, languageCode) : loc(data, languageCode, opts)

/**
	 * Converts object path to `translations` subpath
	 * 
	 * @param {String} path 						Object path
	 * @param {String} lang 						Language code
	 * @returns {String}								Object path to `translations` equivalent
	 */
export const lPath = (path, lang) => {
	if (!lang) {
		throw "Second parameter `lang` required"
	}

	if (lang === "fi") {
		return path
	}
	return `translations.${lang}.${path}`
}

/**
 * Mimics the function of `Formik.getFieldProps` and returns Formik-compatible input props,
 * but uses the input language to determine the formik state paths.
 * 
 * @param {Object} formik 						Formik object
 * @param {String} path 							`_.set` and `setFieldValue` compatible path; dot notation is allowed
 * @param {String} lang 							Language code; controls path for setting and getting localized value
 * @returns {Object}									Input props
 */
export const getLangFieldProps = (formik, path, lang) => {
	const { values, setFieldValue, setFieldTouched, errors, touched } = formik

	const name = lPath(path, lang)
	const value = _.get(values, lPath(path, lang)) ?? ""
	const onChange = e => setFieldValue(lPath(path, lang), e.target.value)
	const onBlur = e => setFieldTouched(lPath(path, lang))
	const isInvalid = _.get(touched, lPath(path, lang)) && _.get(errors, lPath(path, lang))

	return { name, value, onChange, onBlur, isInvalid }
}


/**
 * Returns a flat dictionary of survey translations keyed by non-translated strings
 * 
 * @param {Object} survey 								Survey document or object
 * @param {String} languageCode 					Language code, defaults to i18n.language
 * @returns {Object}											Dictionary of translations
 */
export const getSurveyTranslationDictionary = (survey, languageCode) => {
	let dic = {}

	if (!languageCode) {
		languageCode = i18n.language
	}

	const translation = survey?.translations?.[languageCode]
	if (!translation) {
		return dic
	}

	dic[survey.name] = translation.name
	dic[survey.description] = translation.description
	dic[survey.tag] = translation.tag
	
	for (let i = 0; i < translation.questionGroups?.length; i++) {
		const transQg = _.get(translation, ["questionGroups", i])
		const qgMatch = _.get(survey, ["questionGroups", i])
		_.entries(transQg).filter(([k,v]) => _.isString(v)).forEach(([k,v]) => {
			const str = qgMatch[k]
			const translated = transQg[k]
			_.set(dic, str, translated)
		})
		for (let j = 0; j < transQg.questions?.length; j++) {
			const transQ = _.get(translation, ["questionGroups", i, "questions", j])
			const qMatch = _.get(survey, ["questionGroups", i, "questions", j])
			_.entries(transQ).filter(([k,v]) => _.isString(v)).forEach(([k, v]) => {
				const str = qMatch[k]
				const translated = transQ[k]
				_.set(dic, str, translated)
			})
			for (let k = 0; k < transQ?.choices?.length; k++) {
				const transC = _.get(translation, ["questionGroups", i, "questions", j, "choices", k])
				const cMatch = _.get(survey, ["questionGroups", i, "questions", j, "choices", k])
				if (cMatch) {
					_.entries(transC).filter(([k,v]) => _.isString(v)).forEach(([key,v]) => {
						const str = cMatch[key]
						const translated = transC[key]
						_.set(dic, str, translated)
					})
				}
			}
		}
	}

	for (let i = 0; i < translation.questions?.length; i++) {
		const transQ = _.get(translation, ["questions", i])
		const qMatch = _.get(survey, ["questions", i])
		_.entries(transQ).filter(([k,v]) => _.isString(v)).forEach(([k, v]) => {
			const str = qMatch[k]
			const translated = transQ[k]
			_.set(dic, str, translated)
		})
		for (let j = 0; j < transQ?.choices?.length; j++) {
			const transC = _.get(translation, ["questions", i, "choices", j])
			const cMatch = _.get(survey, ["questions", i, "choices", j])
			_.entries(transC).filter(([k,v]) => _.isString(v)).forEach(([k,v]) => {
				const str = cMatch[k]
				const translated = transC[k]
				_.set(dic, str, translated)
			})
		}
	}

	return dic
}

/**
 * Receives list of potential path segments and recursively applies translations to object
 * attributes. Will mutate input object.
 * 
 * @param {[String]} pathParts 					Path segments in array. Array paths are suffixed with '[]'.
 * @param {Object} obj 									Object to mutate
 * @param {Object} dic 									Translation dictionary
 * @param {Number} pathPartIndex 				Current path segment index, used in recursion
 * @param {[String]} accPath 						Current accumulated path, used in recursion
 * @returns {Object}										Object with translations applied.
 */
const rSetLocAttributes = (pathParts, obj, dic, pathPartIndex=0, accPath=[]) => {
	const curPart = pathParts[pathPartIndex]
	if (!curPart) {
		let v = _.get(obj, accPath)
		if (v && dic[v]) {
			_.set(obj, accPath, dic[v])
		}
		return
	}

	const isArrayPath = curPart.slice(-2) === "[]"
	
	if (isArrayPath) {
		const plainPath = curPart.slice(0,-2)
		let newAccPath = [...accPath, plainPath]
		let arr = _.get(obj, newAccPath) ?? []
		for (let i = 0; i < arr.length; i++) {
			let accPathIndexed = [...accPath, plainPath, i]
			rSetLocAttributes(pathParts, obj, dic, pathPartIndex+1, accPathIndexed)
		}
	} else {
		let newAccPath = [...accPath, curPart]
		rSetLocAttributes(pathParts, obj, dic, pathPartIndex+1, newAccPath)
	}
}

/**
 * Mutates input `responseBody` with localized attribute values based on
 * matched survey `translations` object for the current language.
 * 
 * @param {[String]} paths							Dot-notation paths; array path segments are denoted with '[]' suffix
 * @param {Object} responseBody 				Response body
 * @param {[Object]} surveyData					List of surveys
 * @param {String} languageCode 				Language code, defaults to i18n.language
 * @returns {Object}										Translated response body
 */
const updateLocPaths = (paths, responseBody, surveyData, languageCode) => {
	if (!languageCode) {
		languageCode = i18n.language
	}

	if (!surveyData || !responseBody) {
		return responseBody
	}

	responseBody = _.cloneDeep(responseBody)

	for (let item of responseBody) {

		const survey = surveyData.find(s => s.name === item.surveyName)
		if (!survey) {
			continue
		}

		const translation = survey?.translations?.[languageCode]
		if (!translation) {
			continue
		}

		const dic = getSurveyTranslationDictionary(survey, languageCode)

		for (let path of paths) {
			const parts = path.split(".")
			rSetLocAttributes(parts, item, dic)
		}
	}

	return responseBody
}

/**
 * Specifically translates the response from `getEventSurveyChartData`.
 * 
 * @param {Object} responseBody 				Response body
 * @param {[Object]} surveyData					List of surveys
 * @param {String} languageCode 				Language code, defaults to i18n.language
 * @returns {Object}										Translated response body
 */
export const localizeEventSurveyChartData = (responseBody, surveyData, languageCode) => {

	const paths = [
		"surveyName",
		"data.questionsAvg[].name",
		"data.groupsAvg[].name",
		"data.groupsAvg[].questions[].name",
		"data.questionsN[].name",
		"data.questionsN[].choices[].name",
		"data.groupsN[].name",
		"data.groupsN[].questions[].name",
		"data.groupsN[].questions[].choices[].name",
		"data.questions[].name",
		"data.questionGroups[].name",
		"data.questionGroups[].questions[].name"
	]

	responseBody = updateLocPaths(paths, responseBody, surveyData, languageCode)

	return responseBody
}

/**
 * Specifically translates the response from `getPlayerSurveyDiariesExt`.
 * 
 * @param {Object} responseBody 				Response body
 * @param {[Object]} surveyData					List of surveys
 * @param {String} languageCode 				Language code, defaults to i18n.language
 * @returns {Object}										Translated response body
 */
export const localizePlayerSurveyDiariesExt = (responseBody, surveyData, languageCode) => {

	const paths = [
		"surveyName",
		"answers[].tag",
		"answersByTag[].tag"
	]

	responseBody = updateLocPaths(paths, responseBody, surveyData, languageCode)

	return responseBody
}


/**
 * Specifically translates the response from `getSurveyChartData`.
 * 
 * @param {Object} responseBody 				Response body
 * @param {[Object]} surveyData					List of surveys
 * @param {String} languageCode 				Language code, defaults to i18n.language
 * @returns {Object}										Translated response body
 */
export const localizeGetSurveyChartData = (responseBody, surveyData, languageCode) => {

	const paths = [
		"surveyName",
		"questionGroupAverages[].name",
		"questionAverages[].name"
	]

	responseBody = updateLocPaths(paths, responseBody, surveyData, languageCode)

	return responseBody
}
